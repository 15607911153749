import React, { useState, useEffect } from 'react'
import './Doctor_issue_farm.css';
import html2pdf from 'html2pdf.js';
import Connection from "../../connection";
import jsPDF from 'jspdf';

export default function Doctor_issue_farm() {

  const [issues, setIssues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState(false);
  const [symptoms, setSymptoms] = useState(false);


  useEffect(() => {
    getIssues()
  }, [])

  const getIssues = async () => {
    try {
      let result = await fetch(Connection + "getIssues", {
        headers: {
          'Authorization': localStorage.getItem("JWT_TOKEN")
        }
      })
      result = await result.json()
      const respons4 = result.response;

      if (respons4 === 'Token verification failed') {
        console.log(respons4);
      }
      else if (respons4 === 'fail') {
        console.log(respons4);
      }
      else {
        setIssues(respons4)

        setReason(respons4[0].reason)
        setSymptoms(respons4[0].symptoms)
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  }

  const handle_text_Download = () => {
    const text = 'Hello, world!';
    const filename = 'myText.txt';
    const blob = new Blob([text], { type: 'text/plain' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleDownload = () => {
    const objects = issues
    // const arr = Object.entries(data);
    // const dataType = typeof arr;

    // // const objectss = JSON.stringify(data);
    // console.log(".........",arr)
    // console.log(".........",dataType)

    // const objects = [
    //   { id: 1, name: reason, email: symptoms },
    //   { id: 2, name: reason, email: symptoms },
    // //   { id: 3, name: 'Bob Smith', email: 'bobsmith@example.com' },
    // ];

    console.log(".........", objects)

    const doc = new jsPDF();
    doc.text('Array of Objects', 10, 10);
    let y = 20;
    objects.forEach(obj => {
      doc.text(`ID: ${obj.id}, Reason: ${obj.reason}, Symptoms: ${obj.symptoms}, Issue: ${obj.issue}, Allergic: ${obj.allergic}`, 10, y);
      y += 10;
    });
    doc.save('myObjects.pdf');
  };

  const data = [
    { name: 'John', age: 30, email: 'john@example.com', address: '123 Main St' },
    { name: 'Sarah', age: 25, email: 'sarah@example.com', address: '456 Elm St' },
    { name: 'Mark', age: 35, email: 'mark@example.com', address: '789 Oak St' }
  ];

  const handleClick = () => {
    const objects = issues
    const doc = new jsPDF();
    let y = 20;
    objects.forEach((item) => {
      const text = `Patient Name: ${item.user_name}\nID: ${item.id}\nReason: ${item.reason}\nSymptoms: ${item.symptoms}\nIssue: ${item.issue}\nAllergic: ${item.allergic}`;
      doc.text(text, 20, y);
      y += 50; // increase Y-coordinate by 30 for each object
    });
    doc.save('example.pdf');
  };


  const handleClick1 = () => {
    const objects = issues
    const len = objects.length
    const doc = new jsPDF();
    let y = 10;
    doc.setFontSize(12);
    for (let i = 0; i <= len - 1; i++) {
      doc.text(`Line ${objects[i].id}`, 10, y);
      doc.text(`Reason ${objects[i].reason}`, 10, y);
      //   doc.text(`Symptoms ${objects[i].symptoms}`, 10, y);


      //   doc.text(`ID: ${objects[i].id}, Reason: ${objects[i].reason}, Symptoms: ${objects[i].symptoms}, Issue: ${objects[i].issue}, Allergic: ${objects[i].allergic}`, 10, y);
      y += 10;

    }
    doc.save('example.pdf');
    // const objects = issues
    // const doc = new jsPDF();
    // let y = issues;
    // const len = y.length

    // doc.setFontSize(12);
    // for (let i = 1; i <= len; i++) {
    //   doc.text(`ID: ${y.id}, Reason: ${y.reason}, Symptoms: ${y.symptoms}, Issue: ${y.issue}, Allergic: ${y.allergic}`, 10, y);
    // //   doc.text(`Line ${i}`, 10, y);
    //   y += 10;
    //   if (i % 30 === 0) {
    //     doc.addPage();
    //     doc.setPage(i / 30 + 1);
    //     y = 10;
    //   }
    // }
    // doc.save('example.pdf');
  };


  return (
    <div className='table_review'>

      <div className='responsive_review'>
        <div className='head_card'>
          <h2>All Forms</h2>
        </div>
        <hr></hr>


        <div className='Review_Card'>

          {issues.map((row) => (
            <div className='review_all'>
              <div className='review_details'>

                <div style={{ display: 'flex', gap: 30 }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <h5>Patient Name:</h5>
                    <p>{row.user_name}</p>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <h5>Email:</h5>
                    <p>meha64374@gmail.com</p>
                  </div>
                </div>

                <div style={{}}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <h5>Reasion:</h5>
                    <p>{row.reason}</p>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <h5>Symptoms:</h5>
                    <p>{row.symptoms}</p>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <h5>Issues:</h5>
                    <p>{row.issue}</p>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <h5>Allergic:</h5>
                    <p>{row.allergic}</p>
                  </div>

                  <div style={{ alignItems: 'center' }}>
                    <h5>Note:</h5>
                    <p>{row.medication}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className='action_btn'>
            <button onClick={handleClick}>Download Farm</button>
          </div>
        </div>
      </div>

    </div>
  );
}