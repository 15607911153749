import React, { useState, useEffect, createContext } from 'react'
import Connection from "./connection";
import './App.css';
import { jwtDecode } from "jwt-decode";

import Sidebar from './MyComponent/sidebar/Sidebar';
import Topbar from './MyComponent/topbar/Topbar';

import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Fucture_update from './pages/Fucture_update/Fucture_update';
import Appointment from './pages/Appointment/Appointment';
import Add_doctor from './pages/Add_doctor/Add_doctor';
import Add_patient from './pages/Add_patient/Add_patient';
import Patient_list from './pages/Patient_list/Patient_list';
import Pending_patient from './pages/Pending_patient/Pending_patient';
import Doctor_list from './pages/Doctor_list/Doctor_list';
import Doctor_issue_farm from './pages/Doctor_issue_farm/Doctor_issue_farm';
import Doctor_Details from './pages/Doctor_Details/Doctor_Details';
import Refund from './pages/Refund/Refund';
import Add_funds from './pages/Add_funds/Add_funds';
import Pending_doctor from './pages/Pending_doctor/Pending_doctor';
import Transactions from './pages/Transactions/Transactions';
import Pending_payout from './pages/Pending_payout/Pending_payout';
import Add_Promo from './pages/Add_Promo/Add_Promo';
import All_Promo from './pages/All_Promo/All_Promo';
import Pending_acp from './pages/Pending_acp/Pending_acp';
import ACP_list from './pages/ACP_list/ACP_list';

import Privatecom from './pages/Privatecom'

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"

export const GlobalInfo = createContext()

function App() {

   const [auth, setAuth] = useState('');

   const [appointment, setAppointment] = useState([]);
   const [doctor, setDoctor] = useState([]);
   const [patient, setPatient] = useState([]);

   const [app_count, setApp_count] = React.useState(0);
   const [user_count, setUser_count] = React.useState(0);
   const [doctor_count, setDoctor_count] = React.useState(0);
   const [pen_doctor_count, setPen_doctor_count] = React.useState(0);

   const loginenter = () => {
      const auth = localStorage.getItem("user");
      setAuth(auth)

      if (auth != null) {
         const parsed = JSON.parse(auth);
         const id = parsed[0].id;

         const jwt_tokens = localStorage.getItem("JWT_TOKEN");
         if (jwt_tokens) {
            const decodedToken = jwtDecode(jwt_tokens);

            const currentTimestamp = Date.now() / 1000; // Convert milliseconds to seconds
            if (decodedToken.exp < currentTimestamp) {
               console.log("Token is expired");
               refreshjwttoken(id)
            }
            else {
               console.log("Token is not expired");
               get_all_counting(jwt_tokens)
               get_appointment(jwt_tokens)
               get_doctor(jwt_tokens)
               get_user(jwt_tokens)
            }
         }
         else {
            console.log("No Token");
            refreshjwttoken(id)
         }
      }
   }


   useEffect(() => {
      console.log("auth", auth)
      loginenter()
   }, [])


   const refreshjwttoken = async (id) => {
      try {
         let result = await fetch(Connection + "Jwtadmin_TokenGernate", {
            method: 'post',
            body: JSON.stringify({ id }),
            headers: {
               "Content-Type": 'application/json'
            }
         })
         result = await result.json()
         const record4 = result.response

         if (record4 === 'fail') {
            console.log('Record Fail');
         }
         else {
            const Token = result.token
            localStorage.setItem("JWT_TOKEN", (Token))
            get_all_counting(Token)
            get_appointment(Token)
            get_doctor(Token)
            get_user(Token)
         }
      } catch (error) {
         console.error('Error:', error);
      }
   };

   const get_all_counting = async (Token) => {
      try {
         let result = await fetch(Connection + "get_count", {
            headers: {
               'Authorization': Token
            }
         })
         result = await result.json()
         const respons4 = result.response;

         if (respons4 === 'Token verification failed') {
            console.log(respons4);
         }
         else if (respons4 === 'fail') {
            console.log(respons4);
         }
         else {
            const app_count = respons4[0].app_count
            const user_count = respons4[0].user_count
            const doctor_count = respons4[0].doctor_count
            const pen_doctor_count = respons4[0].pen_doctor_count

            setApp_count(app_count)
            setUser_count(user_count)
            setDoctor_count(doctor_count)
            setPen_doctor_count(pen_doctor_count)
         }
      } catch (error) {
         console.error('Error:', error);
      }
   }

   const get_appointment = async (Token) => {
      try {
         let result = await fetch(Connection + "das_get_appointment", {
            headers: {
               'Authorization': Token
            }
         })
         result = await result.json()
         const respons4 = result.response;

         if (respons4 === 'Token verification failed') {
            console.log(respons4);
         }
         else if (respons4 === 'fail') {
            console.log(respons4);
         }
         else {
            setAppointment(respons4)
         }
      } catch (error) {
         console.error('Error:', error);
      }
   }

   const get_doctor = async (Token) => {
      try {
         let result = await fetch(Connection + "das_get_doctor", {
            headers: {
               'Authorization': Token
            }
         })
         result = await result.json()
         const respons4 = result.response;

         if (respons4 === 'Token verification failed') {
            console.log(respons4);
         }
         else if (respons4 === 'fail') {
            console.log(respons4);
         }
         else {
            setDoctor(respons4)
         }
      } catch (error) {
         console.error('Error:', error);
      }
   }

   const get_user = async (Token) => {
      try {
         let result = await fetch(Connection + "das_get_user", {
            headers: {
               'Authorization': Token
            }
         })
         result = await result.json()
         const respons4 = result.response;

         if (respons4 === 'Token verification failed') {
            console.log(respons4);
         }
         else if (respons4 === 'fail') {
            console.log(respons4);
         }
         else {
            setPatient(respons4)
         }
      } catch (error) {
         console.error('Error:', error);
      }
   }


   return (

      <GlobalInfo.Provider value={{ loginenter: loginenter, appointment: appointment, doctor: doctor, patient: patient, app_count: app_count, user_count: user_count, doctor_count: doctor_count, pen_doctor_count: pen_doctor_count }}>
         <BrowserRouter>
            {!auth ? (
               <Routes>
                  <Route path='/login' element={<Login />}></Route>
                  <Route path='/*' element={<Login />}></Route>
               </Routes>
            ) : (
               <div className='app'>
                  <Sidebar />


                  <div className='topbar_page'>
                     <Topbar />


                     <Routes>
                        <Route element={<Privatecom />} >

                           <Route path='/' element={<Home />}></Route>
                           <Route path='/Fucture_update' element={<Fucture_update />}></Route>
                           <Route path='/Appointment' element={<Appointment />}></Route>
                           <Route path='/Add_doctor' element={<Add_doctor />}></Route>
                           <Route path='/Add_patient' element={<Add_patient />}></Route>
                           <Route path='/Patient_list' element={<Patient_list />}></Route>
                           <Route path='/Pending_patient' element={<Pending_patient />}></Route>
                           <Route path='/Doctor_list' element={<Doctor_list />}></Route>
                           <Route path='/Doctor_issue_farm' element={<Doctor_issue_farm />}></Route>
                           <Route path='/Doctor_Details/:id' element={<Doctor_Details />}></Route>
                           <Route path='/refund' element={<Refund />}></Route>
                           <Route path='/Add_funds' element={<Add_funds />}></Route>
                           <Route path='/Pending_doctor' element={<Pending_doctor />}></Route>
                           <Route path='/Pending_acp' element={<Pending_acp />}></Route>
                           <Route path='/ACP_list' element={<ACP_list />}></Route>
                           <Route path='/Transactions' element={<Transactions />}></Route>
                           <Route path='/Pending_payout' element={<Pending_payout />}></Route>
                           <Route path='/Add_Promo' element={<Add_Promo />}></Route>
                           <Route path='/All_Promo' element={<All_Promo />}></Route>
                           <Route path='/*' element={<Navigate to={'/'} />}></Route>
                        </Route>

                     </Routes>
                  </div>
               </div>
            )}
         </BrowserRouter>
      </GlobalInfo.Provider>

   );
}

export default App;
