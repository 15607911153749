import React, { useState, useEffect } from 'react'
import './Add_Promo.css';
import Connection from "../../connection";
import PulseLoader from "react-spinners/PulseLoader";
import { BrowserRouter, Routes, Route, useNavigate, Link } from "react-router-dom"
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';

export default function Add_Promo() {
    const navigat = useNavigate()

    const [error, setError] = useState(false);
    const [code, setAdd_promocode] = useState("");
    const [percentage, setAdd_percentage] = useState("");
    const [allow, setAdd_Allow] = useState('off');

    const [isLoading, setIsLoading] = useState(false);
    const [modalsuccess, setModalsuccess] = useState(false);

    const Adupdate = async (event) => {
        event.preventDefault();

        try {
            if (!code) {
                setError(true)
                alert('Please Enter Promo Code.');
                return false
            }

            if (!percentage) {
                setError(true)
                alert('Please Enter Percentage.');
                return false
            }

            setIsLoading(true);

            console.log(code)

            let result = await fetch(Connection + "add_promo_code", {
                method: 'post',
                body: JSON.stringify({ code, percentage, allow }),
                headers: {
                    'Authorization': localStorage.getItem("JWT_TOKEN"),
                    "Content-Type": 'application/json'
                }
            })
            result = await result.json()
            const respons4 = result.response;
            setIsLoading(false);

            if (respons4 === 'Token verification failed') {
                console.log(respons4);
                alert('Something went wrong')
            }
            else if (respons4 === 'fail') {
                console.log(respons4);
                alert('Something went wrong')
            }
            else {
                setModalsuccess(true)
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
            alert('Something went wrong')
        }
    }


    const Succeess = async (event) => {
        setModalsuccess(false)
        navigat('/')
    }


    return (
        <div className='add_prommo'>

            <div className='adprom_responsive'>
                <div className='head_card'>
                    <h2>Add Promo</h2>
                </div>
                <hr></hr>


                <div className='prom_ad_Card'>

                    <div className="ad_code_input">
                        <div className='adprom_width_subject'>
                            <div className='subject-name'>
                                <label>Add promo</label>
                            </div>
                            <textarea className="message" onChange={(e) => setAdd_promocode(e.target.value)} placeholder="Abc123" id="prmadd_inpu" ></textarea>
                        </div>
                    </div>

                    <div className="ad_code_input">
                        <div className='adprom_width_subject'>
                            <div className='subject-name'>
                                <label>Add percentage</label>
                            </div>
                            <textarea className="message" onChange={(e) => setAdd_percentage(e.target.value)} placeholder="25%" id="prmadd_inpu" ></textarea>
                        </div>
                    </div>

                    <div className="ad_code_input">
                        <div className='adprom_width_subject'>
                            <div className='width-name'>
                                <div className='subject-name'>
                                    <label>Allow</label>
                                </div>
                                <select id="name" onChange={(e) => setAdd_Allow(e.target.value)}>
                                    <option>{allow}</option>
                                    <option value="on">On</option>
                                    <option value="off">Off</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="Updatebuttun">
                        {isLoading ?
                            <button class="submit" id='loader' type="reset">
                                <PulseLoader
                                    color={"white"}
                                    loading={isLoading}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={Adupdate} class="submit" id='submite' type="submit">Add Promo</button>
                        }
                    </div>
                </div>
            </div>


            <Modal size='lg' isOpen={modalsuccess} toggle={() => setModalsuccess(!modalsuccess)}>
                <ModalHeader toggle={() => setModalsuccess(!modalsuccess)}>
                    Successfully
                </ModalHeader>

                <ModalBody>
                    <div className='succeess_modal'>
                        <h4>You have successfully Add Promo Code</h4>
                        <button onClick={Succeess}>Done</button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}