import React, { useState, useEffect } from 'react'
import './Pending_acp.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Link, parsePath, useParams, useNavigate } from "react-router-dom"
import Pending_acp_Paginations from './Pending_acp_Paginations';
import PulseLoader from "react-spinners/PulseLoader";

export default function Pending_acp() {
    const navigate = useNavigate();

    const [modalaproved, setModalaproved] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const [products, setProducts] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);

    const lastPostIndex = currentPage * postsPerPage
    const firstPostIndex = lastPostIndex - postsPerPage
    const currentPosts = products.slice(firstPostIndex, lastPostIndex)

    const [user_id, setUser_id] = React.useState("");
    const [member_id, setMember_id] = React.useState("");

    const [patLoading, setPatLoading] = useState(false);

    const params = useParams();

    useEffect(() => {
        get_pendingacp()
    }, [])


    const get_pendingacp = async () => {
        try {
            let result = await fetch('https://acp.virtualmedicos.com/rest_apis.php?action=get_pendingacp')
            result = await result.json()
            const respons4 = result.response;
            console.log("respons4 =>", respons4)

            if (respons4 === 'fail') {
                console.log(respons4);
            }
            else {
                setProducts(respons4)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }


    const open_approved_modal = (id) => {
        setModalaproved(true)
        setUser_id(id)
    }

    const approved_patient = async (event) => {
        event.preventDefault();

        if (member_id == '') {
            alert("Please Enter Member ID.")
            return true
        }

        try {
            let uploaddata = new FormData();

            setPatLoading(true);

            console.log("User ID =>", user_id)
            console.log("Member ID =>", member_id)

            uploaddata.append('user_id', user_id);
            uploaddata.append('member_id', member_id);

            let api = 'https://acp.virtualmedicos.com/rest_apis.php?action=approved_acpdoctor';
            console.log("Api for approved => ", api)

            const response = await fetch(api, {
                method: 'POST',
                body: uploaddata,
            })

            const jsonResponse = await response.json();
            const respons4 = jsonResponse.response;
            console.log("respons4.respons4", respons4)

            setPatLoading(false);

            if (respons4 === 'fail') {
                console.log(respons4);
                alert('Something went wrong')
            }
            else {
                setModalaproved(false)
                navigate('/ACP_list')
            }
        } catch (error) {
            console.error('Error:', error);
            setPatLoading(false);
            alert('Something went wrong')
        }
    }



    return (
        <div className='table_view'>

            <div className='responsive_card'>
                <div className='head_card'>
                    <h2>PENDING ACP</h2>
                </div>
                <hr></hr>

                <div className='table_responsive'>
                    <table className='table_tag'>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>


                        {currentPosts.map((row) => (
                            <tr>
                                <td>{row.id}</td>
                                <td>{row.name}</td>
                                <td>{row.email}</td>
                                <td>{row.address}</td>
                                <td>{row.status}</td>
                                <td>
                                    <span className='aution_btn'>
                                        <button onClick={() => open_approved_modal(row.id)}>Approved</button>
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>

            <Pending_acp_Paginations totalPosts={products.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage} />

            <Modal size='lg' isOpen={modalaproved} toggle={() => setModalaproved(!modalaproved)}>
                <ModalHeader toggle={() => setModalaproved(!modalaproved)}>
                    APPROVED ACP DOCTOR
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to approved this ACP doctor?</h4>

                        <div className="Amount_Main">
                            <div className='label_head'>
                                <div className='label_div'>
                                    <label>Member ID</label>
                                </div>
                                <input type="number" className="amount" id="subject" placeholder="Enter Member ID" onChange={(e) => setMember_id(e.target.value)} />
                            </div>
                        </div>

                        {patLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={patLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={approved_patient}>Approved</button>
                        }
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}