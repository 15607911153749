import React, { useState, useEffect } from 'react'
import './Patient_list.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Link, parsePath, useParams } from "react-router-dom"
import Pagination from './Paginations';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import { user_profile } from '../../Image_connection';

export default function Patient_list() {

   const [modalEdit, setModalEdit] = useState(false);
   const [modalDelete, setModalDelete] = useState(false);

   const [products, setProducts] = useState([]);
   const [allpatient, setAllpatient] = useState([]);

   const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage, setPostsPerPage] = useState(10);

   const lastPostIndex = currentPage * postsPerPage
   const firstPostIndex = lastPostIndex - postsPerPage
   const currentPosts = products.slice(firstPostIndex, lastPostIndex)

   const [user_id, setUser_id] = React.useState("");
   const [name, setName] = React.useState("");
   const [mobile_number, setMobile_number] = React.useState("");
   const [email, setEmail] = React.useState("");
   const [address, setAddress] = React.useState("");
   const [password, setPassword] = React.useState("");
   const [free_hisd, setFree_hisd] = React.useState(true);
   const [city, setCity] = useState("Country");

   const [just_Show, setJust_Show] = useState(null);
   const [profile, setProfile] = React.useState("");

   const [patLoading, setPatLoading] = useState(false);

   const params = useParams();

   useEffect(() => {
      getProducts()
   }, [])


   const getProducts = async () => {
      try {
         let result = await fetch(Connection + "get_user", {
            headers: {
               'Authorization': localStorage.getItem("JWT_TOKEN")
            }
         })
         result = await result.json()
         const respons4 = result.response;

         if (respons4 === 'Token verification failed') {
            console.log(respons4);
         }
         else if (respons4 === 'fail') {
            console.log(respons4);
         }
         else {
            setProducts(respons4)
            setAllpatient(respons4)
         }
      } catch (error) {
         console.error('Error:', error);
      }
   }



   const getAddress = (event) => {
      event.preventDefault();

      if (!city) {
         alert('Please select Country');
         return false
      }
      else if (city == 'Country') {
         alert('Please select Country');
         return false
      }
      else {
         setPatLoading(true);

         let api = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyAr6YRTjfl2eKXIJZd97_uw9KpStXFRiCE`;
         console.log("pass => ", api);

         fetch(api, {
            method: "POST",
         })
            .then((response) => response.json())
            .then((response) => {
               console.log("pass => ", response);
               let result = response["results"];
               if (result == "") {
                  setPatLoading(false);
                  alert("Invalid Address.")
               }
               else {
                  //getting lat lng from user address
                  let all = result[0].geometry;
                  let location = all.location;
                  let lat = location["lat"];
                  let lng = location["lng"];

                  console.log("lat => ", lat);
                  console.log("lng => ", lng);
                  // setLat(lat);
                  // setLng(lng);

                  Update_patient(lat, lng)
               }
            })
            .catch((error) => {
               setPatLoading(false);
               alert("Something went wrong.")
               console.error(error);
            });
      }
   };


   const Update_patient = async (lat, lng) => {
      try {
         console.warn(name, email, mobile_number, address, password, free_hisd, city)

         let result = await fetch(Connection + `update_patient/${user_id}`, {
            method: 'post',
            body: JSON.stringify({ name, email, mobile_number, address, lat, lng, password, free_hisd, city }),
            headers: {
               'Authorization': localStorage.getItem("JWT_TOKEN"),
               "Content-Type": 'application/json'
            }
         })
         result = await result.json()
         const respons4 = result.response;
         setPatLoading(false);

         if (respons4 === 'Token verification failed') {
            console.log(respons4);
            alert('Something went wrong')
         }
         else if (respons4 === 'fail') {
            console.log(respons4);
            alert('Something went wrong')
         }
         else {
            setModalEdit(false)
            setTimeout(() => {
               getProducts()
            }, 100);
         }
      } catch (error) {
         console.error('Error:', error);
         alert('Something went wrong')
         setPatLoading(false);
      }
   }

   function fileSelectedHandler(event) {
      const file = event.target.files[0];
      // setFile(event.target.files[0]);
      const reader = new FileReader();
      reader.onload = function (event) {
         const imageUrl = event.target.result;
         setJust_Show(imageUrl);
      };
      reader.readAsDataURL(file);
      console.log("file", file)
      setTimeout(() => {
         update_pati_pic(file)
      }, 100);
   }

   const update_pati_pic = async (file) => {
      // event.preventDefault();

      console.log("connect_file1", file)

      setPatLoading(true);

      const form_data = new FormData();

      form_data.append("patient", file);

      const api = Connection + `update_pati_pic/${user_id}`
      const result = await fetch(api, {
         method: "POST",
         body: form_data,
      })
         .then((result) => result.json())
         .then((result) => {
            console.log("result", result);
            getProducts()
            setPatLoading(false);
         })

         .catch((error) => {
            console.error(error);
            setPatLoading(false);
            alert("Something went wrong")
         });

      console.log("result123", result)
   }


   const open_edit_modal = (id, name, email, mobile_number, address, password, profile, free_hisd, city) => {
      setModalEdit(true)

      setUser_id(id)
      setName(name)
      setEmail(email)
      setMobile_number(mobile_number)
      setAddress(address)
      setPassword(password)
      setProfile(profile)
      setFree_hisd(free_hisd)
      setCity(city)
   }

   const open_delete_modal = (id) => {
      setModalDelete(true)

      setUser_id(id)
   }

   const Search_Doctor = async (event) => {
      event.preventDefault();

      try {
         const name = event.target.value

         let result = await fetch(Connection + "search_patient", {
            method: 'post',
            body: JSON.stringify({ name }),
            headers: {
               'Authorization': localStorage.getItem("JWT_TOKEN"),
               "Content-Type": 'application/json'
            }
         })
         result = await result.json()
         const respons4 = result.response;

         if (respons4 === 'Token verification failed') {
            console.log(respons4);
         }
         else if (respons4 === 'fail') {
            console.log(respons4);
         }
         else {
            setProducts(respons4)
         }
      } catch (error) {
         console.error('Error:', error);
      }
   }


   const Serach_doctor = (e) => {
      const searchText = e ? e.target.value.toLowerCase().replace(/[\s+]/g, '') : '';

      if (searchText === "") {
         setProducts(allpatient);
      }
      else {
         const results = allpatient.filter(item =>
            (item.name && item.name.toLowerCase().includes(searchText)) ||
            (item.email && item.email.toLowerCase().includes(searchText)) ||
            (item.mobile_number && item.mobile_number.replace(/[\s+]/g, '').includes(searchText))
         );

         setProducts(results);
      }
   };


   const Delete_patient = async (event) => {
      event.preventDefault();

      try {
         setPatLoading(true);

         let result = await fetch(Connection + `delete_patient/${user_id}`, {
            headers: {
               'Authorization': localStorage.getItem("JWT_TOKEN")
            }
         })
         result = await result.json()
         const respons4 = result.response;
         setPatLoading(false);

         if (respons4 === 'Token verification failed') {
            console.log(respons4);
            alert('Something went wrong')
         }
         else if (respons4 === 'fail') {
            console.log(respons4);
            alert('Something went wrong')
         }
         else {
            setModalDelete(false)
            getProducts()
         }
      } catch (error) {
         console.error('Error:', error);
         setPatLoading(false);
         alert('Something went wrong')
      }
   }


   return (
      <div className='table_view'>

         <div className='responsive_card'>
            <div className='head_card'>
               <h2>PATIENT</h2>
               <div className='search_patient'>
                  <h6>Search</h6>
                  <input type="search" className="search" id="Search" placeholder="Search" onChange={Serach_doctor} />
               </div>
            </div>
            <hr></hr>

            <div className='table_responsive'>
               <table className='table_tag'>
                  <tr>
                     <th>ID</th>
                     <th>Image</th>
                     <th>Name</th>
                     <th>Email</th>
                     <th>Phone</th>
                     <th>Action</th>
                  </tr>


                  {currentPosts.map((row) => (
                     <tr>
                        <td>{row.id}</td>
                        <td>
                           {row.profile == null ?
                              <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                              :
                              <img src={user_profile + row.profile} title="Profile Image" alt="" />
                           }
                        </td>
                        <td>{row.name}</td>
                        <td>{row.email}</td>
                        <td>{row.mobile_number}</td>
                        <td>
                           <span className='aution_btn'>
                              <button onClick={() => open_edit_modal(row.id, row.name, row.email, row.mobile_number, row.address, row.password, row.profile, row.free_hisd, row.city)}>Edit</button>
                              <button onClick={() => open_delete_modal(row.id)}>Remove</button>
                           </span>
                        </td>
                     </tr>
                  ))}
               </table>
            </div>
         </div>

         <Pagination totalPosts={products.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage} />

         <Modal size='lg' isOpen={modalEdit} toggle={() => setModalEdit(!modalEdit)}>
            <ModalHeader toggle={() => setModalEdit(!modalEdit)}>
               UPDATE PATIENT
            </ModalHeader>

            <ModalBody>

               <form className='Form_all_patient'>

                  {/* <div className='profileImage'>
                        {profile == null ?
                            <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt=""/>
                            :
                            <>
                            {just_Show == null ?
                              <img src={user_profile + profile} title="Profile Image" alt=""/>
                              :
                              <img src={just_Show} alt="" className="fab fa-firstdraft" />
                            }
                            </>
                         }
                          <div className="custom_file_input">
                             <input type="file" onChange={fileSelectedHandler} />
                             <label>Update Profile</label>
                          </div>
                  </div> */}

                  <div class="patient_first_name">
                     <div className='update_width_both_name_email'>
                        <div className='update_width_name'>
                           <div className='updt_subject_name'>
                              <label>Name</label>
                           </div>
                           <input type="text" class="" id="name" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>

                        <div className='update_width_name'>
                           <div className='updt_subject_name'>
                              <label>Email</label>
                           </div>
                           <input type="email" class="" id="email" placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                     </div>
                  </div>

                  {/* <div class="update_second_subject">
                  <div className='updat_width_subject'>
                     <div className='updt_subject_name'>
                        <label>Address</label>
                     </div>
                     <input type="text" class="subject" id="subject" placeholder="Address" value={address}  onChange={(e)=>setAddress(e.target.value)}/>
                  </div>
               </div> */}

                  <div class="patient_first_name">
                     <div className='update_width_both_name_email'>
                        <div className='update_width_name'>
                           <div className='updt_subject_name'>
                              <label>Mobile Number</label>
                           </div>
                           <input type="number" class="" id="email" placeholder="Mobile Number" value={mobile_number} onChange={(e) => setMobile_number(e.target.value)} />
                        </div>

                        <div className='update_width_name'>
                           <div className='updt_subject_name'>
                              <label>Password</label>
                           </div>
                           <input type="text" class="" id="name" placeholder="Your Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                     </div>
                  </div>


                  <div className="first-name">
                     <div className='width-both-name-email'>
                        <div className='update_width_name'>
                           <div className='updt_subject_name'>
                              <label>Address</label>
                           </div>
                           <input type="text" class="" id="email" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </div>


                        <div className='width-name'>
                           <div className='subject-name'>
                              <label>Country</label>
                           </div>
                           <select id="subject" value={city} onChange={(e) => setCity(e.target.value)}>
                              <option>{city}</option>
                              <option value="Texas">Texas</option>
                              <option value="California">California</option>
                              <option value="Arizona">Arizona</option>
                              <option value="Louisiana">Louisiana</option>
                              <option value="New Mexico">New Mexico</option>
                              <option value="Florida">Florida</option>
                              <option value="Alabama">Alabama</option>
                              <option value="Alaska">Alaska</option>
                              <option value="Arkansas">Arkansas</option>
                              <option value="Colorado">Colorado</option>
                              <option value="Connecticut">Connecticut</option>
                              <option value="Delaware">Delaware</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Hawaii">Hawaii</option>
                              <option value="Idaho">Idaho</option>
                              <option value="Illinois">Illinois</option>
                              <option value="Indiana">Indiana</option>
                              <option value="Iowa">Iowa</option>
                              <option value="Kansas">Kansas</option>
                              <option value="Kentucky">Kentucky</option>
                              <option value="Maine">Maine</option>
                              <option value="Maryland">Maryland</option>
                              <option value="Massachusetts">Massachusetts</option>
                              <option value="Michigan">Michigan</option>
                              <option value="Minnesota">Minnesota</option>
                              <option value="Mississippi">Mississippi</option>
                              <option value="Missouri">Missouri</option>
                              <option value="Montana">Montana</option>
                              <option value="Nebraska">Nebraska</option>
                              <option value="Nevada">Nevada</option>
                              <option value="New Hampshire">New Hampshire</option>
                              <option value="New Jersey">New Jersey</option>
                              <option value="New York">New York</option>
                              <option value="North Carolina">North Carolina</option>
                              <option value="North Dakota">North Dakota</option>
                              <option value="Ohio">Ohio</option>
                              <option value="Oklahoma">Oklahoma</option>
                              <option value="Oregon">Oregon</option>
                              <option value="Pennsylvania">Pennsylvania</option>
                              <option value="Rhode Island">Rhode Island</option>
                              <option value="South Carolina">South Carolina</option>
                              <option value="South Dakota">South Dakota</option>
                              <option value="Tennessee">Tennessee</option>
                              <option value="Utah">Utah</option>
                              <option value="Vermont">Vermont</option>
                              <option value="Virginia">Virginia</option>
                              <option value="Washington">Washington</option>
                              <option value="West Virginia">West Virginia</option>
                              <option value="Wisconsin">Wisconsin</option>
                              <option value="Wyoming">Wyoming</option>
                           </select>
                        </div>
                     </div>
                  </div>


                  <div class="updt_seond_subject">
                     <div className='updt_width_suject'>
                        <div className='updatsubject_name'>
                           <label>Free hisd</label>
                        </div>

                        <select id="name" value={free_hisd} onChange={(e) => setFree_hisd(e.target.value)}>
                           <option value="true">True</option>
                           <option value="false">False</option>
                        </select>
                     </div>
                  </div>

                  <div class="update_buttun">
                     <button class="submit" id='reset' type="reset">Reset</button>
                     {patLoading ?
                        <button class="submit" id='loader' type="reset">
                           <PulseLoader
                              color={"white"}
                              loading={patLoading}
                              //  size={5}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                           />
                        </button>
                        :
                        <button onClick={getAddress} class="submit" id='submite' type="submit">Update</button>
                     }
                  </div>
               </form>

            </ModalBody>
         </Modal>

         <Modal size='lg' isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
            <ModalHeader toggle={() => setModalDelete(!modalDelete)}>
               DELETE PATIENT
            </ModalHeader>

            <ModalBody>
               <div className='delete_modal'>
                  <h4>Are you sure you want to delete this patient?</h4>
                  {patLoading ?
                     <button id='delete_loader'>
                        <PulseLoader
                           color={"white"}
                           loading={patLoading}
                           //  size={5}
                           aria-label="Loading Spinner"
                           data-testid="loader"
                        />
                     </button>
                     :
                     <button onClick={Delete_patient}>Delete</button>
                  }
               </div>
            </ModalBody>
         </Modal>
      </div>
   );
}