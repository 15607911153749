import React, { useState, useEffect } from 'react'
import './ACP_list.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Link, parsePath, useParams } from "react-router-dom"
import ACP_list_Paginations from './ACP_list_Paginations';
import PulseLoader from "react-spinners/PulseLoader";

export default function ACP_list() {

    const [aCPdoc, setACPdoc] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);

    const lastPostIndex = currentPage * postsPerPage
    const firstPostIndex = lastPostIndex - postsPerPage
    const currentPosts = aCPdoc.slice(firstPostIndex, lastPostIndex)

    const [modalDelete, setModalDelete] = useState(false);

    const [id, setId] = React.useState("");

    const [patLoading, setPatLoading] = useState(false);

    const params = useParams();

    useEffect(() => {
        get_alACP()
    }, [])

    const get_alACP = async () => {
        try {
            let result = await fetch('https://acp.virtualmedicos.com/rest_apis.php?action=get_alllacp')
            result = await result.json()
            const respons4 = result.response;

            if (respons4 === 'fail') {
                console.log(respons4);
            }
            else {
                setACPdoc(respons4)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }


    const open_deletepromo_modal = (id) => {
        setModalDelete(true)

        setId(id)
    }

    const Delete_acpdoc = async (event) => {
        event.preventDefault();

        try {
            let uploaddata = new FormData();

            setPatLoading(true);

            uploaddata.append('id', id);

            let api = 'https://acp.virtualmedicos.com/rest_apis.php?action=delete_acpdoctor';
            console.log("Api for delete acp => ", api)

            const response = await fetch(api, {
                method: 'POST',
                body: uploaddata,
            })
            const jsonResponse = await response.json();
            const respons4 = jsonResponse.response;

            setPatLoading(false);

            if (respons4 === 'fail') {
                console.log(respons4);
                alert('Something went wrong')
            }
            else {
                setModalDelete(false)
                setTimeout(() => {
                    get_alACP()
                }, 100);
            }
        } catch (error) {
            console.error('Error:', error);
            setPatLoading(false);
            alert('Something went wrong')
        }
    }

    return (
        <div className='Add_funds_main_table'>

            <div className='respons_Add_funds_card'>
                <div className='head_card'>
                    <h2>All ACP</h2>
                </div>
                <hr></hr>

                <div className='Add_funds_table'>
                    <table className='Add_refunds_table_tag'>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>


                        {currentPosts.map((row) => (
                            <tr>
                                <td>{row.id}</td>
                                <td>{row.name}</td>
                                <td>{row.email}</td>
                                <td>{row.address}</td>
                                <td>{row.status}</td>
                                <td>
                                    <span className='transfer_btn'>
                                        <button onClick={() => open_deletepromo_modal(row.id)}>Delete ACP</button>
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>

            <ACP_list_Paginations totalPosts={aCPdoc.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage} />

            <Modal size='lg' isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
                <ModalHeader toggle={() => setModalDelete(!modalDelete)}>
                    DELETE ACP DOCTOR
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to delete this ACP Doctor?</h4>
                        {patLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={patLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={Delete_acpdoc}>Delete</button>
                        }
                    </div>
                </ModalBody>
            </Modal>

        </div>
    );
}