import React, { useState, useEffect } from 'react'
import './Transactions.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Link, parsePath, useParams } from "react-router-dom"
import Transactions_Paginations from './Transactions_Paginations';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";

export default function Transactions() {

  const [transactions, setTransactions] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  const currentPosts = transactions.slice(firstPostIndex, lastPostIndex)

  const params = useParams();

  useEffect(() => {
    get_Transactions()
  }, [])


  const get_Transactions = async () => {
    try {
      let result = await fetch(Connection + "get_wallet", {
        headers: {
          'Authorization': localStorage.getItem("JWT_TOKEN")
        }
      })
      result = await result.json()
      const respons4 = result.response;

      if (respons4 === 'Token verification failed') {
        console.log(respons4);
      }
      else if (respons4 === 'fail') {
        console.log(respons4);
      }
      else {
        setTransactions(respons4)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }



  return (
    <div className='Transactions_main_table'>

      <div className='respons_transtion_card'>
        <div className='head_card'>
          <h2>Transactions</h2>
        </div>
        <hr></hr>

        <div className='transcation_table'>
          <table className='trans_tble_tag'>
            <tr>
              <th>Appointment Id</th>
              <th>User Id</th>
              <th>Doctor Id</th>
              <th>Doctor Earning</th>
              <th>Company's Earning</th>
              <th>Payment Method</th>
              <th>Date</th>
            </tr>


            {currentPosts.map((row) => (
              <tr>
                <td>{row.appointment_id}</td>
                <td>{row.user_id}</td>
                <td>{row.doctor_id}</td>
                <td>${row.d_earnings}</td>
                <td>${row.profit}</td>
                <td>{row.payment_method}</td>
                <td>{row.date}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>

      <Transactions_Paginations totalPosts={transactions.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage} />

    </div>
  );
}